@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
:root {
	--white: #fff;
	--black: #000;
	--primary: #231d4f;
	--secondary: #bb6bd9;
	--icon: #9b57b3;
	--grey: #f8f3f3;
	--headingColor: #505050;
	--paraColor: #848199;
}
body {
	font-family: "Poppins", sans-serif;
}
.top_header {
	background-color: var(--white);
}
.top_header nav.navbar {
	background-color: var(--white);
}
/* .top_header .navbar-brand img{
	min-width: fit-content;
} */
.top_header a.account_btn {
	border-radius: 7px;
	background-color: var(--primary);
	color: var(--white);
	font-weight: 600;
	font-size: 16px;
	padding: 9px 35px;
	border: none;
}
.top_header a.account_btn:active,
.top_header a.account_btn:focus {
	background-color: var(--primary);
	color: var(--white);
}
.top_header .slack_btn {
	background-color: transparent;
	border: none;
	padding: 0;
	margin-right: 20px;
}
.top_header .slack_btn:active,
.top_header .slack_btn:focus {
	background-color: transparent;
}
.top_header .slack_btn img{
	min-width: fit-content;
}
.top_header .nav_left {
	background-color: var(--grey);
	border-radius: 50px;
	margin-right: 40px;
}
.top_header .nav_left .nav-link {
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	padding: 0;
	margin: 0 25px;
	transition: .3s;
	overflow: hidden;
}
.top_header .nav_left .nav-link .nav_icon {
	width: 56px;
	height: 56px;
	border-radius: 50%;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}
.top_header .nav_left .nav-link .nav_label {
	font-weight: 600;
	font-size: 9px;
	color: var(--black);
	margin-top: 3px;
	/* display: none; */
	opacity: 0;
	margin-bottom: -17px;
}
.top_header .nav_left .nav-link:hover{
	margin-top: -27px;
}
.top_header .nav_left .nav-link:hover .nav_icon{
	background-color: #D6EDF6;
	border: 5px solid var(--white);
}
.top_header .nav_left .nav-link:hover .nav_label{
	opacity: 1;
}

.slack_floating {
	background-color: transparent;
	border: none;
	padding: 0;
	position: fixed;
	right: 20px;
	bottom: 20px;
}

.top_banner {
	background-image: url(/src/images/top_bg.png);
	background-size: cover;
	background-position: center;
	padding-top: 120px;
	padding-bottom: 160px;
	min-height: calc(100vh - 115px);
}

.top_banner h1 {
	color: var(--headingColor);
	font-weight: 800;
	font-size: 70px;
	text-shadow: -1px 15px 4px #dbf0ff;
	margin-bottom: 65px;
}
.top_banner h3 {
	font-weight: 400;
	font-size: 24px;
	line-height: 29px;
	color: #7a778e;
	margin-bottom: 70px;
}
.top_banner .slack_btn {
	border: none;
	background: transparent;
	padding: 0;
	display: inline-block;
	margin-left: 60px;
}
.top_banner .slack_btn img{
	border-radius: 7px;
}

.reset_banner {
	background-image: url(/src/images/blur_bg.png);
	background-position: center;
	background-size: cover;
	padding-top: 210px;
	padding-bottom: 150px;
	text-align: center;
}
.reset_banner .reset_img {
	margin-left: 50px;
}
.reset_banner h2 {
	color: var(--paraColor);
	font-weight: 400;
	font-size: 30px;
	line-height: 46px;
	margin-bottom: 70px;
	margin-top: 15px;
	padding: 0 80px;
}
.reset_banner h5 {
	color: var(--black);
	font-weight: 400;
	font-size: 19px;
	line-height: 28px;
}

/* How it Works */
.cm-according-img {
	right: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.cm-according-head {
    font-size: 20px;
	padding-right: 50px !important;
}

/* Price Section */
.price_section {
	padding-top: 100px;
	padding-bottom: 100px;
}
.price_section .section_title h2 {
	font-weight: 400;
	font-size: 40px;
	color: var(--primary);
}
.price_section .section_title h5 {
	font-weight: 500;
	font-size: 18px;
	line-height: 30px;
	color: var(--paraColor);
}
.price_section .nav-item a {
	background-color: var(--white);
	color: var(--primary);
	border-radius: 50px;
	border: none;
	padding: 13px 24px;
	letter-spacing: 0.833333px;
	margin-left: 10px;
}
.price_section .nav-item a.active {
	background-color: var(--secondary);
	color: var(--white);
	box-shadow: 0px 5px 7px rgba(82, 67, 194, 0.230196);
}
.package_item {
	background-color: var(--white);
	border-radius: 26px;
	padding: 50px 58px 30px;
	transition: 0.3s;
}
.package_item h2 {
	color: var(--primary);
	margin-bottom: 20px;
	font-weight: 700;
	font-size: 36px;
}
.package_item h2 small {
	color: var(--paraColor);
	margin-left: 20px;
	font-weight: 500;
	font-size: 17px;
}
.package_item h3 {
	color: var(--primary);
	font-weight: 500;
	font-size: 28px;
	margin-bottom: 10px;
}
.package_item p {
	color: var(--paraColor);
	font-weight: 500;
	font-size: 15px;
	margin-bottom: 35px;
}
.package_item ul {
	padding-left: 0;
	margin-bottom: 75px;
}
.package_item ul li {
	color: var(--paraColor);
	font-weight: 500;
	font-size: 15px;
	margin-bottom: 8px;
	list-style-type: none;
	padding-left: 30px;
}
.package_item ul li::before {
	content: "";
	width: 20px;
	height: 20px;
	border-radius: 20px;
	background-image: url(/src/images/icons/check.svg);
	display: inline-block;
	margin-right: 10px;
	vertical-align: middle;
	margin-left: -30px;
}
.package_item a.btn {
	background-color: var(--primary);
	opacity: 0.5;
	border: none;
	padding: 12px 55px;
	border-radius: 50px;
	font-weight: 500;
	font-size: 15px;
}
.package_item a.btn:hover,
.package_item a.btn:active,
.package_item:hover a.btn, .package_item.active a.btn {
	background-color: var(--secondary);
	color: var(--white);
	opacity: 1;
}
.package_item:hover, .package_item.active {
	background-color: var(--primary);
	box-shadow: 0px 42px 34px rgba(82, 67, 194, 0.295755);
}
.package_item:hover h2, .package_item.active h2,
.package_item:hover h2 small, .package_item.active h2 small,
.package_item:hover h3, .package_item.active h3,
.package_item:hover p, .package_item.active p {
	color: var(--white);
}
.prices_area {
	padding-top: 130px;
	padding-bottom: 80px;
}

/* Video Section */
.video_section {
	background-image: url(/src/images/blur_bg.png);
	background-position: center;
	background-size: cover;
	padding-top: 150px;
	padding-bottom: 150px;
	overflow: hidden;
}
.video_section video {
	border-radius: 50px;
}

/* --Footer-- */
.contact_form {
	padding-top: 70px;
	padding-bottom: 90px;
}
.contact_form h2 {
	color: #4e4751;
	font-weight: 700;
	font-size: 40px;
	margin-bottom: 20px;
}
.contact_form h5 {
	color: var(--black);
	font-weight: 400;
	font-size: 20px;
	margin-bottom: 55px;
}
.contact_form input {
	background-color: #eae7e7;
	border-radius: 35px;
	border: none;
	height: 80px;
	padding: 30px 40px;
	font-size: 15px;
}
.contact_form input::placeholder {
	color: #a19d9d;
}
.contact_form input:focus {
	background-color: #eae7e7;
	box-shadow: none;
}
.contact_form button.btn {
	width: 100%;
	border-radius: 50px;
	color: var(--white);
	background-color: var(--primary);
	border: none;
	font-weight: 400;
	font-size: 20px;
	padding: 25px 20px;
	text-transform: uppercase;
}
.bottom_footer {
	padding: 45px 0;
}
.bottom_footer .footer_social {
	display: inline-flex;
	margin: 0;
	padding: 0;
	width: 100%;
	justify-content: flex-end;
}
.bottom_footer .footer_social li {
	list-style-type: none;
}
.bottom_footer .footer_social li a {
	background-color: transparent;
	border-radius: 0;
	border: none;
	padding: 0;
	margin: 0 15px;
}
.bottom_footer .footer_social li a:focus,
.bottom_footer .footer_social li a:active,
.footer_logo:focus,
.footer_logo:active,
.footer_logo:hover,
.quicklinks li a:focus,
.quicklinks li a:active,
.quicklinks li a:hover {
	background-color: transparent !important;
	border: none !important;
}
.footer_logo {
	background-color: transparent;
	border: none;
	padding: 0;
}

.quicklinks {
	display: inline-flex;
	justify-content: space-between;
	margin: 0;
	padding: 0;
	width: 100%;
}
.quicklinks li {
	list-style-type: none;
}
.quicklinks li a {
	color: var(--black);
	font-weight: 400;
	font-size: 24px;
	padding: 0 10px;
	background-color: transparent;
	border: none;
}
.quicklinks li a:hover {
	color: var(--black);
}

.page-iframe{
	width: 99vw;
	height: 80vh;
	border:0;
  }

.dashboard-button{
	display: flex;
	justify-content: center;
	align-items: center;
	border: 4px solid transparent;
	background-color: var(--primary);
	width: 60px;
	padding: 10px;
	border-radius: 30px;
	margin: 5px 5px;
	height: 60px;
	transition: all 0.4s ease-in;
}


.dashboard-button.active{
	background-color: var(--secondary);
}


.dashboard-button:hover:not(.active){
	border: 4px solid var(--secondary);
	background-color: var(--primary);
}

.sidebar {
background-color: var(--white);
display:flex;
flex-direction: column;
align-items: center;
border-radius: 200px;
width: 80px;
padding: 10px 0;
margin-top: 10px;
box-shadow: 4px 6px 15px var(--headingColor) ;
}

.dashboard {
	background-image: url(/src/images/top_bg.png);
	background-size: cover;
	background-position: center;
	padding-top: 10px;
	padding-bottom: 160px;
	min-height: calc(100vh - 115px);
}
.dashboard h1 {
	color: var(--primary);
	font-weight: 800;
	font-size: 50px;
	margin-bottom: 30px;
}
.dashboard h2 {
	color: var(--headingColor);
	font-weight: 800;
	font-size: 30px;
}
.dashboard h3 {
	font-weight: 400;
	font-size: 24px;
	line-height: 29px;
	color: #7a778e;
	margin-bottom: 70px;
}
.inProgress{
	display:'flex';
	justify-content:'center';
	align-items:'center';
	text-align:'center';
}
.stripe-body{
	box-shadow: 5px 7px 10px 0px rgba(82, 67, 194, 0.295755);
}
.btn-secondary {
	border-radius: 7px;
	background-color: var(--primary);
	color: var(--white);
	font-weight: 600;
	font-size: 16px;
	padding: 9px 20px;
	border: none;
}

.button-active, .btn-secondary:hover {
	border-radius: 7px;
	background-color: var(--secondary);
}

.period{
	margin-bottom: 5px;
	text-align: left;
	width: 60%;
}

.login {
	background-image: url(/src/images/top_bg.png);
	background-size: cover;
	background-position: center;
	display:flex;
	padding-top: 10vh;
	padding-bottom: 10vh;
	min-height: calc(100vh - 115px);
	justify-content: center;
}
.rightDivider {
	border-right: 1px solid rgba(0, 0, 0, .25);
}

.purchaseSection{
	display: flex;
	flex-direction: row;
	width: 100%;
}
.purchasePrice {
	margin-top: 40px;
	width: 50%;
}

.purchasePrice ul {
	padding-left: 0;
	text-align: left;
	margin-bottom: 40px;
}
.purchasePrice ul li {
	color: var(--grey);
	font-weight: 500;
	font-size: 15px;
	margin-bottom: 8px;
	list-style-type: none;
	padding-left: 30px;
	white-space: nowrap;

}
.purchasePrice ul li::before {
	content: "";
	width: 20px;
	height: 20px;
	border-radius: 20px;
	background-image: url(/src/images/icons/check.svg);
	display: inline-block;
	margin-right: 10px;
	vertical-align: middle;
	margin-left: -30px;
}

.purchasePrice .btn{
	background-color: var(--primary);
	width: 80%;
	border:0;
	color: var(--white);
	opacity: 1;
	box-shadow: 0px 5px 7px rgba(82, 67, 194, 0.230196);
}

.purchasePrice .btn.selected{
	background-color: var(--secondary);
	color: var(--white);
	opacity: 1;
}

.purchasePrice .btn:hover{
	background-color: var(--secondary);
	color: var(--white);
	opacity: 1;
}

.purchaseButton {
	border-radius: 7px;
	background-color: var(--primary);
	color: var(--white);
	font-weight: 600;
	font-size: 20px;
	padding: 9px 20px;
	border: none;
}

.card-title{
	font-size: 26px;
	font-weight: 700;
	margin-bottom:25px;
	color: var(--primary);
}
/* Login Page  */
.cm-login-btm-info {
	
}
@media (max-width: 1199px) and (min-width: 992px) {
	.top_banner h1 {
		font-size: 60px;
		margin-bottom: 65px;
	}
	.top_banner h3 {
		font-size: 20px;
	}
	.package_item {
		padding: 40px 30px 30px;
	}
}

@media (max-width: 992px) {
	.top_banner {
		padding-top: 70px;
		padding-bottom: 80px;
	}
	.top_banner h1 {
		font-size: 44px;
		margin-bottom: 30px;
	}
	.top_banner h3 {
		font-size: 18px;
		margin-bottom: 40px;
	}
	.top_banner .slack_btn {
		margin-left: 0;
	}
	.top_banner .slack_btn img {
		height: 55px;
	}
	.reset_banner {
		padding-top: 100px;
		padding-bottom: 80px;
	}
	.reset_banner h2 {
		font-size: 26px;
		line-height: 40px;
		margin-bottom: 40px;
		padding: 0 40px;
	}
	.reset_banner h5 {
		font-size: 18px;
	}
	.price_section .nav-pills {
		padding-top: 30px;
	}
	.price_section .nav-item a {
		margin-left: 0;
		margin-right: 10px;
	}
	.prices_area {
		padding-top: 40px;
		padding-bottom: 0px;
	}
	.package_item a.btn {
		padding: 12px 25px;
	}
	.package_item ul {
		margin-bottom: 45px;
	}
	.package_item {
		padding: 20px 20px 30px;
	}
	.package_item h3 {
		font-size: 22px;
	}
	.package_item h2 small {
		margin-left: 0;
	}
	.video_section video {
		border-radius: 40px;
	}
	.video_section {
		padding-top: 80px;
		padding-bottom: 80px;
	}
	.contact_form {
		padding-top: 70px;
		padding-bottom: 40px;
	}
	.quicklinks li a {
		font-size: 16px;
	}
	.top_header .nav_left .nav-link {
		margin: 0 7px;
	}
	.top_header .nav_left {
		margin-right: 20px;
	}
	.top_header .nav_left .nav-link .nav_icon {
		width: 50px;
		height: 50px;
	}

	.purchasePrice ul {
		display: none;
	}
}

@media (max-width: 767px) {
	/* Header CSS */
		.cm-mobile-navbar.navbar-nav {
			flex-direction: row;
		}
	
		.cm-mobile-menu-wrap>a {
			background: #F8F3F3;
			margin-bottom: 10px;
			padding: 10px;
			border-radius: 25px;
			padding-left: 30px;
		}
	
		.cm-mobile-menu-wrap>a span.nav_icon img {
			width: 20px;
			height: 20px;
			object-fit: contain;
			object-position: center;
		}
	
		.cm-mobile-menu-wrap>a span.nav_icon {
			display: inline-block;
			margin-right: 10px;
		}
	
		/* Banner Section */
		.cm-mb-col-center {
			text-align: center;
		}
	
		/* Price Carousel */
		.cm-bg-wrap {
			min-height: 510px;
		}
	
		.carousel-control-next span,
		.carousel-control-prev span {
			background-color: #bb6bd9;
		}
	
		.cm-mb-price-carousel .carousel-indicators {
			background-color: #bb6bd9;
			border-radius: 10px;
		}
	
		.cm-mb-price-carousel .carousel-inner .carousel-caption>div {
			width: 100%;
		}
	
		.cm-mb-price-carousel .carousel-inner .carousel-caption>div .package_item {
			box-shadow: 0px 4px 5px rgba(82, 67, 194, 0.295755);
		}
	.reset_banner h2 {
		font-size: 22px;
		line-height: 32px;
		margin-bottom: 30px;
		padding: 0 0px;
	}
	.reset_banner h5 {
		font-size: 16px;
		line-height: 24px;
	}
	.reset_banner {
		padding-top: 70px;
		padding-bottom: 60px;
	}
	.price_section {
		padding-top: 70px;
		padding-bottom: 50px;
	}
	.bottom_footer .footer_logo {
		display: block;
		margin: 0 auto 30px;
	}
	.bottom_footer .footer_social {
		justify-content: center;
		padding-top: 40px;
	}
	.contact_form input {
		height: 60px;
		padding: 20px 40px;
	}
	.contact_form button.btn {
		padding: 15px 20px;
	}
	.top_header .nav_left {
		margin-right: 0px;
	}
}
@media (max-width: 575px) {
	.quicklinks li a {
		font-size: 14px;
	}
	.contact_form h5 {
		line-height: 24px;
		font-size: 16px;
		padding: 0 30px;
	}
	.video_section video {
		border-radius: 25px;
	}
	.price_section .section_title h5 {
		font-size: 16px;
		line-height: 26px;
	}
	.top_banner {
		padding-top: 50px;
		padding-bottom: 80px;
	}
	.top_banner h1 {
		font-size: 36px;
		margin-bottom: 20px;
	}
	.top_banner h3 {
		margin-bottom: 25px;
	}
	.top_header .nav_left .nav-link .nav_icon {
		width: 40px;
		height: 40px;
	}
	.top_header .nav_left .nav-link .nav_icon img {
		max-width: 20px;
		max-height: 20px;
	}
	.top_header .nav_left .nav-link {
		margin: 0 0px;
	}
	.top_header .nav_left {
		padding: 0 5px;
	}
	.top_header .navbar-brand img{
		width: 110px;
		min-width: auto;
	}
}
/* @media (prefers-color-scheme: dark) {
	html {
		color-scheme: dark;
	}

	body {
		color: white !important;
		background: black;
	}
} */

.navbar-brand img,
.footer_logo img {
    width: 100%;
    max-width: 140px;
}
#basic-navbar-nav.show {
    background: rgb(255 255 255 / 60%);
}
.custom-pricing-sec{
	padding-top: 70px;
    padding-bottom: 90px;
	border-top: 2px solid #918EA7;
}
.cm-custom-price-info {
    text-align: center;
    font-style: italic;
    max-width: 790px;
    margin: 0 auto;
    font-size: 20px;
    color: #808080;
}
